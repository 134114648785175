
import { defineComponent } from 'vue';
import gcd from '../helpers/gcd';

export default defineComponent({
  name: 'CalculatorRatio',
  data() {
    return {
      width: '',
      height: '',
      ratio: '',
      widthHasError: false,
      heightHasError: false,
    };
  },
  methods: {
    calculateRatio() {
      // check if inputfields are valid and throw error if not
      if (this.isInvalid()) return;

      // calculate and set ratio
      if (this.height && this.width) this.setRatio();
    },
    isInvalid(): boolean {
      // regex test, only allow numbers
      const regex = /^\d*\.?\d*$/;
      const testWidth = regex.test(this.width);
      const testHeight = regex.test(this.height);
      const failedValidation = !testWidth || !testHeight;

      this.widthHasError = !testWidth;
      this.heightHasError = !testHeight;

      return failedValidation;
    },
    setRatio() {
      const width = parseInt(this.width, 10);
      const height = parseInt(this.height, 10);
      const ratio = gcd(width / height, 50);
      this.ratio = ratio;
    },
    resetForm() {
      this.width = '';
      this.height = '';
      this.ratio = '';
      this.widthHasError = false;
      this.heightHasError = false;
    },
  },
});
