import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ca607d12"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "frame" }
const _hoisted_2 = { class: "frame__inner" }
const _hoisted_3 = { class: "grid-container" }
const _hoisted_4 = { class: "grid-col grid-col--left" }
const _hoisted_5 = { class: "grid-col grid-col--right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CalculatorSize = _resolveComponent("CalculatorSize")!
  const _component_CalculatorRatio = _resolveComponent("CalculatorRatio")!
  const _component_ImageUploader = _resolveComponent("ImageUploader")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_CalculatorSize)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_CalculatorRatio)
          ])
        ])
      ])
    ]),
    _createVNode(_component_ImageUploader)
  ], 64))
}