
import { defineComponent } from 'vue';
import gcd from '../helpers/gcd';

export default defineComponent({
  name: 'ImageUploader',
  data() {
    return {
      width: '',
      height: '',
      ratio: '',
      hasFile: false,
    };
  },
  methods: {
    getImageInfos(event: Event) {
      const target = event.target as HTMLInputElement;
      const { files } = target;
      const file = files && files[0];
      const image = new Image();
      const url = window.URL || window.webkitURL;
      const objectUrl = url.createObjectURL(file);

      image.onload = () => {
        url.revokeObjectURL(objectUrl);

        this.width = `${image.width}px`;
        this.height = `${image.height}px`;
        this.setRatio(image.width, image.height);
        this.hasFile = true;
      };
      image.src = objectUrl;
    },
    setRatio(width: number, height: number) {
      const ratio = gcd(width / height, 50);
      this.ratio = ratio;
    },
  },
});
