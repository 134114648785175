
import { defineComponent } from 'vue';
import CalculatorSize from './CalculatorSize.vue';
import CalculatorRatio from './CalculatorRatio.vue';
import ImageUploader from './ImageUploader.vue';

export default defineComponent({
  name: 'FormBox',
  components: {
    CalculatorSize,
    CalculatorRatio,
    ImageUploader,
  },
});
