
import { defineComponent } from 'vue';
import { inputTypes } from '../types';

export default defineComponent({
  name: 'CalculatorSize',
  data() {
    return {
      width: '',
      height: '',
      ratio: '',
      widthHasError: false,
      heightHasError: false,
      ratioHasError: false,
      lastInputUsed: '' as inputTypes,
    };
  },
  computed: {
    convertedRatio(): { rat1: number; rat2: number; } {
      const [aspectRatioDenominator, aspectRatioNumerator] = this.ratio.split(':');
      const rat1 = parseInt(aspectRatioDenominator, 10);
      const rat2 = parseInt(aspectRatioNumerator, 10);

      return {
        rat1,
        rat2,
      };
    },
  },
  methods: {
    updateLastInputUsed(type: inputTypes) {
      this.lastInputUsed = type;
    },
    calculateSize() {
      // check if inputfield is valid and throw error if not
      if (this.inputIsInvalid('ratio', this.ratio)) return;

      // if ratio field or width & height are empty, return
      if (!this.ratio || (!this.height && !this.width)) return;

      // if width and height are set
      if (this.width && this.height) {
        // if width or ratio input was last used, calculate height
        if (this.lastInputUsed === 'width' || this.lastInputUsed === 'ratio') {
          this.calculateHeight();
        }
        // if height input was last used, calculate width
        if (this.lastInputUsed === 'height') {
          this.calculateWidth();
        }
      }

      // if height is missing or width & height are already set => calculate height
      // calculate height, if width input is set
      if ((this.width && !this.height)) {
        this.calculateHeight();
      }

      // calculate width, if height input is set
      if (this.height && !this.width) {
        this.calculateWidth();
      }
    },
    calculateHeight() {
      // check if inputfield is valid and throw error if not
      if (this.inputIsInvalid('width', this.width)) return;

      // ratio and width must be set to start calculation
      if (!this.ratio || !this.width) return;

      const { rat1, rat2 } = this.convertedRatio;
      const ratio = parseInt(this.width, 10) / rat1;
      const calculatedHeight = Math.round(ratio * rat2);
      this.height = `${calculatedHeight}`;
    },
    calculateWidth() {
      // check if inputfield is valid and throw error if not
      if (this.inputIsInvalid('height', this.height)) return;

      // ratio and height must be set to start calculation
      if (!this.ratio || !this.height) return;

      const { rat1, rat2 } = this.convertedRatio;
      const calculatedWidth = Math.round(parseInt(this.height, 10) * (rat1 / rat2));
      this.width = `${calculatedWidth}`;
    },
    inputIsInvalid(type: inputTypes, value: string): boolean {
      // regex test, only allow numbers
      const regex = type === 'ratio' ? /^\d*:?\d*$/ : /^\d*\.?\d*$/;
      const testInput = regex.test(value);
      const failedValidation = !testInput;

      if (type === 'width') {
        this.widthHasError = failedValidation;
      }

      if (type === 'height') {
        this.heightHasError = failedValidation;
      }

      if (type === 'ratio') {
        this.ratioHasError = failedValidation;
      }

      return failedValidation;
    },
    resetForm() {
      this.width = '';
      this.height = '';
      this.ratio = '';
      this.widthHasError = false;
      this.heightHasError = false;
      this.ratioHasError = false;
    },
  },
});
