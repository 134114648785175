
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Header',
  props: {
    lightModeActive: Boolean,
  },
  methods: {
    handleClick() {
      this.$emit('toggle-light-mode');
    },
  },
});
